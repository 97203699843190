module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-relative-images","gatsby-remark-normalize-paths",{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"linkImagesToOriginal":false}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41067508-16","anonymizeIP":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"breakpoints":["48em","80em","90em"],"spacing":[0,4,8,16,32,64,128],"fonts":{"body":"\"Rangle Riforma\", \"Helvetica Neue\", Helvetica, sans-serif","heading":"\"Rangle Riforma\", \"Helvetica Neue\", Helvetica, sans-serif","monospace":"Menlo, monospace"},"colors":{"text":"#000","lighttext":"#EBEBEB","content":"#464646","secondary":"#A0A0A0","background":"#fff","ranglered":"#d44527"},"fontSizes":[12,14,16,20,24,26,32,48],"fontWeights":{"body":400,"heading":500,"bold":700},"letterSpacings":{"body":"-.5px","heading":"-1px","content":"-.75px"},"lineHeights":{"body":1.5,"heading":1.1875},"text":{"heading":{"fontFamily":"heading","letterSpacing":"heading","lineHeight":"heading","fontWeight":"heading"}},"styles":{"h1":{"variant":"text.heading","fontSize":6},"h2":{"variant":"text.heading","fontSize":5},"h3":{"variant":"text.heading","fontSize":3,"color":"secondary"},"p":{"fontSize":3,"letterSpacing":"content","lineHeight":"body","color":"content"},"a":{"textDecoration":"none","color":"inherit","transition":"opacity 0.2s ease",":hover":{"transition":"opacity 0.2s ease","opacity":0.5,"textDecorationColor":"inherit"}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
